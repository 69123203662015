@import "custom-styles.scss";
html {
  //Make the scrollbar always visible to prevent layout shift
  overflow-y: scroll;
}

html, body, #root {
  height: 100%;
  margin: 0;
}

.cursor-on-hover {
  cursor: pointer;
}

.clickable-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
    color: #0366d6;
}

.hoverable-icon {
  color: #787878;
  text-decoration: none;
  transition: color 0.3s; /* Smooth transition for color change */

}

.hoverable-icon:hover {
  color: #000000;
}


.alert-popup {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
}

.required-asterisk {
  color: $danger;
  margin-left: 3px;
}

.floating-action-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  border-radius: 50%; /* Circular */
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Navbar*/

.navbar-custom {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.navbar-brand{
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.navbar-brand-text{
  margin-right: 20px;
}

.small-nav-link-container{
  margin-top: 25px;
}

.navbar-dropdown {
  z-index: 1002;
}

.nav-link {
  border-bottom: 2px solid transparent; /* Transparent border for a smooth transition */
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  margin-right: 5px;
  color: $secondary !important;
}

.nav-link.active {
  border-bottom: 2px solid $dark; /* Bottom border */
  color: $dark !important;
}

.nav-icon {
  font-size: 1.3rem;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.large-menu {
  margin-top: 1rem;
}

.no-item-icon {
  font-size: 5rem;
  color: #6c757d;
  margin-bottom: 1rem;
}


.no-item-message {
  display: flex;
  flex-direction: column;
  justify-content: center; // Centers vertically in the flex container
  align-items: center; // Centers horizontally
  height: 100%; // Takes up the full viewport height, adjust as needed
  text-align: center; // Ensures the text within the <p> tag is centered as well

  padding: 2rem 0;
}


/* Footer */
.footer {
  padding: 20px 0;
  text-align: center;
  bottom: 0;
  width: 100%;
}

/* Loading overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75); /* Darker semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Ensure it's above other content */
}

.loading-content {
  text-align: center;
  color: white;
}

.loading-content p {
  margin-top: 15px;
  font-size: 1.2em;
}

/* Top level cards */
.top-level-card {
  margin-bottom: 3rem;
}

.top-level-card .card-header {
    /*Overriding Bootstrap CSS for sticky headers*/
  --bs-card-cap-bg: #f8f5f0;
  height: 55px;
  // Flexbox for vertical centering
  display: flex;
  align-items: center;

  // Handle text overflow with ellipsis
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


  //Sticky card header
  position: sticky;
  top: 0;
  opacity: 1.0;
  z-index: 1000;

}

.plant-form-item {
  // Keeps the form from shifting when switching between editing and not editing parent IDs
  height: 30px;
}

/* Image Modal */
.no-padding-modal {
  --bs-modal-padding: 0rem !important; /* Overrides the default Bootstrap modal padding */
}
.flex-modal-body {
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center children vertically */
    align-items: center; /* Center children horizontally */
    height: 100%; /* Take full height of the modal */
}

.flexible-image {
    max-height: 100%; /* Maximum height is the container's height */
    width: 95vw; /* Maintain the aspect ratio */
    object-fit: contain; /* Ensure the image is fully visible */
}

/* Login Page */
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.auth-card {
  width: 20rem;
  padding: 10px;
  margin: 10px auto;
  border-radius: 8px;
  background-color: #f8f5f0 !important;
}

.auth-logo {
  width: 80%;
  margin: 10px auto;
}

.auth-form-group {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 0px;
}

.create-account-button{
  display: flex;
  justify-content: center;
}

// Plant Image Grid
.card-hoverable:hover .card-img-overlay {
  background: transparent; /* Remove the dark overlay on hover */
}

.card-hoverable:hover .card-title,
.card-hoverable:hover .card-text,
.card-hoverable:hover .card-bottom-content {
  text-shadow: none; /* Remove text shadow on hover */
  color: transparent; /* Make text transparent on hover */
}

.card-hoverable .card-img-overlay {
  transition: background 0.3s ease, color 0.3s ease;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Align content to top and bottom */
  padding: 5px;
}

.card-hoverable .card-title,
.card-hoverable .card-text {
  margin: 0;
  font-weight: bold;
  //text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.custom-card-img {
  height: 175px;
  object-fit: cover;
}

.card-top-content,
.card-bottom-content {
  align-self: flex-start;
}

.image-card-title {
  font-size: 1.0rem !important;
}

// Lineages

.legend-item {
  display: inline-block;
  font-size: 0.9rem;
  padding: 3px 8px;
  background-color: $secondary-bg-subtle;
  border-radius: 5px;
}

.lineage-toast{
  background-color: #ffffff !important;
}

.lineage-toast-header, .lineage-toast-body{
  border-left:5px solid #4B6049 !important;
}

.lineage-toast-header{
  background-color: #f8f5f0 !important;
  border-bottom: 1px solid #4B6049 !important;
}